import vrvomopic from '../misc/VRVoMospring2023.jpg';


export default function Donate () {
    const sponsors = {
        "DIRECTOR'S CIRCLE ($1000+)": [
            "Michael Albert",
            "Deb & Dick Arscott",
            "Richard Critz & Kristina Isakovich",
            "The Maislin Family",
            "Nicholas Maas",
        ],
        "PATRON ($500)": [
            "David & Alexandra Andrews",
            "Carlo Iofreddo",
        ],
        "SUPPORTERS ($250+)": [
            "Michelle Cunningham",
            "Toy & Joe Cobbe (on behalf of Richard Critz)",
            "William Lubke",
            "Katrina Mroz",
            "David & Diane Patterson",
            "Mark Schuldenfrei",
            "Bill Selander",
            "Richard Townsend",
        ],
        "FRIENDS ($100+)": [
            "Tom Batton",
            "Kenneth Blood",
            "Mariel K. Bossert",
            "Anna Chelak",
            "Karen Coxon",
            "Marc & Anne Emmerich",
            "Sandra Kahn",
            "Dale Miller",
            "Michael Moore",
            "Leonard Morrison",
            "Steve & Helene Nester",
            "David & Avery Pierce",
            "Robert J Weggel",
            "Anonymous (1)",
        ],
    };
    return (
        <>
            <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Donations</h1>
            <div className='w-11/12 ml-auto mr-auto'>
                <div className='text-justify bg-slate-800 rounded-2xl p-5'>
                    <p>
                        Want to help our chapter efforts?
                    </p>
                    <p>
                        The Greater Boston Chapter is a non-profit comprised of VocaMotion and <a className ='innerLink' href="https://www.vocalrevolution.org/">Vocal Revolution</a>— our TTBB sibling chorus. The Chapter is a dues-paying organization that includes people from all walks of life: students, professional engineers, doctors, salespeople, lawyers, and many more. Everyone in the chapter helps out; with the exception of our Director, all our work is done by volunteers. 
                        Twice a year, we raise funds for the Chapter during our Spring and Fall shows. We also host an annual singing Valentines fundraiser. The funds we earn from these go right back into the work of the chapter, including hosting community shows like the annual Boston A Cappella festival. These funds also support our “keep the whole world singing” program, which funds scholarships and grants for high school students and programs. 
                        If you would like to donate to our chapter efforts, you can do so by clicking the button below!
                    </p>
                    <div className ='flex justify-center pt-3'>
                        <a className='w-[39%]' href="https://www.vocalrevolution.org/donations"><button style={{width:'100%'}} className ="homePageButton w-full" id="auditionInfoButton">
                        Donate Now!
                        </button>
                        </a>
                    </div>
                    <div className='w-[90%]'>
                        <h2 className = 'h2 w-11/12 pt-16 pb-12'>OUR 2024 SPONSORS</h2>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '50px' }}>
                            {Object.entries(sponsors).map(([title, names], index) => (
                            <div key={index}>
                                <p className='underline font-bold'>{title}</p>
                                {names.map((name, nameIndex) => (
                                <p className='leading-3' key={nameIndex}>{name}</p>
                                ))}
                            </div>
                            ))}
                        </div>
                    </div>
                    <div className='donationImageContainer pt-10'>
                        <img className='donationImage rounded-lg' src={vrvomopic} alt='VR and Vomo Joint Show'></img>
                    </div>
                </div>
                <p className="italic nondiscriminationPolicy p-3"> The Chapter is a 501(c)(3) non-profit with EIN 23-7066982.</p>
            </div>
        </>
    )
}