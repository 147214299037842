import eventBanner1 from '../../misc/WelcomeXmasBanner.png'

export default function HolidayShow () {

    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Holiday Show - 12/7</h1>
            <div className='w-11/12 m-auto'>
                <div className='text-justify pb-10'>
                    <img className='m-auto' src={eventBanner1} alt='Event Banner'></img>
                    <div className='bg-slate-800 rounded-2xl p-5 pt-0 mt-5'>
                        <br></br>
                        <br></br>
                        <p>
                        Ring in the holiday season with an afternoon of a cappella harmony, brought to you by Vocal Revolution and VocaMotion barbershop choruses.
                        </p>
                        <p>
                        On <span className='underline'>Saturday December 7th at 1pm</span>, come enjoy a wide variety of festive tunes performed in the stunning sanctuary of <span className='underline'>Arlington Street Church</span> in Boston’s Back Bay neighborhood. Unique, nostalgic, and family-friendly, this barbershop a cappella show is sure to get any lover of music and harmony into the holiday spirit.
                        </p>
                        <p>
                        Early Bird tickets are available until <span className='underline'>November 30</span>, so don’t delay! Tickets can be purchased at our <a className='innerLink' href='https://www.zeffy.com/ticketing/45cff2d9-34c3-44d6-9ba7-fb49fffd46f3'>Zeffy</a> page. Please note that, as a 501c3 charitable organization, 100% of the proceeds from ticket sales and donations goes back into supporting high-quality music education and hosting stellar community events!
                        </p>
                        <p>
                        For those unable to use public transportation, we have partnered with the Back Bay Garage to provide our audience with a <a className='innerLink' href="https://drive.google.com/file/d/1bog5CBexNzmlsM-NYBrDmz_s5359x3Sp/view?usp=sharing">reduced parking rate</a>. Be sure to download your parking coupon before attending the show.  
                        </p>
                        <br></br>

                        <h3>ABOUT OUR ORGANIZATION</h3>
                        <p>
                        <a className='innerLink' href='https://www.vocalrevolution.org'>Vocal Revolution </a> is the highest-ranked men’s chorus ever in the Northeastern District (NED) of the Barbershop Harmony Society, with 18 District Championship titles. The chorus is dedicated to its mission of contributing to the community through high-quality performances and support of music education.
                        </p>
                        <p>
                        VocaMotion is the only treble-voiced chorus in the Northeastern District (NED) of the Barbershop Harmony Society. The group’s mission is to create a safe and welcoming space for SSAA singers of all gender identities to come together in a cappella harmony. VocaMotion has seen tremendous success since its formation in 2023, and is thrilled to be competing at next summer’s BHS International Chorus Contest in Denver.
                        </p>
                        <p>
                        Visit our websites or on Facebook/Instagram for more info about each chorus, including how to visit or join!
                        </p>
                        <br></br>
                        <p>This event will feature the talents of:
                            <ul className='list-disc'>
                                <li>Vocal Revolution</li>
                                <li>VocaMotion</li>
                            </ul>
                        </p>
                        <p>And quartets:
                            <ul className='list-disc'>
                                <li>Daily Special</li>
                                <li>The Inactive Ingredients</li>
                                <li>The Locals</li>
                                <li>Charlie and the Chord Factory</li>
                                <li>...and more!</li>
                            </ul>
                        </p>
                        <br></br>
                        <p className='pb-1'><span className='underline'>Venue</span>:
                            <a className='innerLink' href='https://www.google.com/maps/place/Arlington+Street+Church/data=!4m2!3m1!1s0x89e37a74fcfcfa25:0xd00def28a48fda65?sa=X&ved=1t:155783&ictx=111'> Arlington Street Church, 351 Boylston St, Boston, MA 02116</a>
                        </p>
                        <p className='pb-1'><span className='underline'>Time</span>: Show starts at 1pm with short intermission. Doors open at 12:30pm for General Admission, 12pm for early entry (+$10).</p>
                        <p><span className='underline'>Ticket Price</span>: $15 (early bird) or $20 (after November 30). Student and child pricing available. Find parking coupons for Back Bay Garage <a className='innerLink' href="https://drive.google.com/file/d/1bog5CBexNzmlsM-NYBrDmz_s5359x3Sp/view?usp=sharing">here</a>.</p>
                    </div> 
                </div>
            </div>
        </>
    )
}